import React, { useMemo, useState } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import NumberField from '@soosap/sushi/Form/NumberField';
import CheckboxField, { CheckboxState } from '@soosap/sushi/Form/CheckboxField';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';

import { Variant, IconName } from 'server/src/app/carte/types';
import styles from './VariantsFieldArray.module.scss';

export interface Props {
  initialValue: Variant[];
}

const VariantsFieldArray: React.FC<Props> = ({
  initialValue: initialValueRaw,
}) => {
  const initialValue = useMemo(() => initialValueRaw, []);
  const [iconOptions] = useState<SelectOption[]>(
    Object.entries(IconName).map((e) => ({ label: e[1], value: e[1] }))
  );

  const { fields } = useFieldArray('variants', {
    multiple: true,
    initialValue,
  });

  return (
    <div className={styles[`VariantsFieldArray`]}>
      {fields.length
        ? fields.map((name, index) => {
            const checked =
              fields.value[index] && fields.value[index]['checked']
                ? fields.value[index]['checked']
                : CheckboxState.UNCHECKED;

            return (
              <FieldSet
                title={`Variant ${index + 1}`}
                key={name}
                className={styles[`VariantsFieldArray__fieldset`]}
                onClose={() => fields.remove(index)}
              >
                <div
                  key={`${name}${index}`}
                  className={styles[`VariantsFieldArray__field`]}
                >
                  <TextField
                    label={index === 0 ? 'Title' : undefined}
                    name={`${name}.title`}
                    placeholder="z.B. Vegane Variante"
                    required
                    requiredError="Pflichtfeld"
                  />
                  <TextField
                    label={index === 0 ? 'Title - Thermal printer' : undefined}
                    name={`${name}.titleThermalPrinter`}
                    placeholder="optional | z.B. vegan, scharf, etc."
                  />
                  <TextAreaField
                    label={index === 0 ? 'Description' : undefined}
                    name={`${name}.description`}
                    placeholder={`optional | z.B. "Auch in der veganen Variante verfügbar. Beim Kothu verzichten wir hier auf das Ei."`}
                  />
                  <NumberField
                    label={index === 0 ? 'Delta' : undefined}
                    name={`${name}.delta`}
                    placeholder="z.B. 150 für +1,50€"
                    required
                    requiredError="Pflichtfeld"
                  />
                  <NumberField
                    name={`${name}.position`}
                    required
                    requiredError="Pflichtfeld"
                    label="Position"
                    placeholder="z.B. 1"
                    initialValue={1}
                  />
                  <div>
                    <CheckboxField
                      name={`${name}checked`}
                      label="Vorausgewählt"
                      initialValue={checked}
                    />
                  </div>
                  <SelectField
                    name={`${name}.iconName`}
                    label="Icon"
                    options={iconOptions}
                    initialValue="none"
                  />
                </div>
              </FieldSet>
            );
          })
        : null}
    </div>
  );
};

export default VariantsFieldArray;
