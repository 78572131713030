export enum IconName {
  VEGETARIAN = 'Vegetarian',
  VEGAN = 'Vegan',
  CHILI = 'Chili',
}

export interface Variant {
  checked: boolean;
  delta: number;
  description?: string; // Das Paruppu können wir auch auf eine vegane Art zubereiten.
  iconName?: IconName;
  id: string;
  position: number;
  title: string; // Vegane Variante
  titleThermalPrinter?: string;
}

export interface Variants {
  [variantId: string]: Variant;
}

export interface Quote {
  id: string;
  price: number; // 390 für 3.90 EUR
  position: number;
  description: string; // 1x, 2cl, XXL, 0.5l
}

export interface Quotes {
  [quoteId: string]: Quote;
}

export enum ProductType {
  FOOD_ITEM = 'FoodItem',
  BEVERAGE = 'Beverage',
}

export interface Offer {
  active: boolean;
  bucketId: string;
  createdAt: number;
  id: string;
  position: number;
  product: FoodItem | Beverage;
  productType: ProductType;
  quotes: Quotes;
  variants?: Variants;
}

export type Offer2 = Offer & {
  quotes: Quote[];
  variants: Variant[];
};

export interface Offers {
  [offerId: string]: Offer;
}

export interface Bucket {
  description?: string;
  id: string;
  externalId?: number;
  offers: Offers;
  position: number;
  title: string;
  titleInTamil?: string;
}

export type Bucket2 = Bucket & {
  offers: Offer2[];
};

export interface Buckets {
  [bucketId: string]: Bucket;
}

export enum CarteType {
  INTERNAL = 'internal',
  CATERING = 'catering',
  PRIVATE = 'private',
}

export interface Carte {
  buckets: Buckets;
  id: string;
  createdAt: string;
  updatedAt: string;
  description?: string;
  position: number;
  shouldDisplayQuotes: boolean;
  subtitle?: string; // Family & Friends
  subtitleInTamil?: string;
  title: string; // Fabian's 25th Birthday
  titleInTamil?: string; // பாபியான் 25வது பிறந்தநாள்
  type: CarteType;
}

export type Carte2 = Carte & {
  buckets: Bucket2[];
};

export interface Cartes {
  [carteId: string]: Carte;
}

export enum BeverageType {
  ALCOHOL_FREE = 'Alkoholfrei',
  APERITIF = 'Aperitif',
  BEER = 'Bier',
  COCKTAIL = 'Cocktail',
  COFFEE = 'Kaffee',
  CHAMPAGNE = 'Schaumwein', // Oberbegriff für Prosecco und Sekt
  SCHNAPPS = 'Schnaps',
  TEA = 'Tee',
  WINE = 'Wein',
}

export enum WineType {
  RED = 'Rotwein',
  WHITE = 'Weißwein',
  ROSE = 'Roséwein',
}

export enum BeerType {
  DRAFT = 'Biere vom Fass',
  BOTTLE = 'Flaschenbiere',
}

export enum SchnappsType {
  GIN = 'Gin',
  RUM = 'Rum', // z.B. Havana Club, Captain Morgan, Bacardi
  WODKA = 'Wodka',
  WHISKEY = 'Whiskey',
  OBSTLER = 'Obstler',
  LIQUEUR = 'Likör', // z.B. Jägermeister
}

export enum CoffeeType {
  ESPRESSO = 'Espresso',
  CAPUCCINO = 'Capuccino',
  LATTE_MACCHIATO = 'Latte Macchiato',
}

export enum TeaType {
  DARJEELING = 'Darjeeling',
  EARL_GREY = 'Earl Grey',
}

export enum AlcoholFreeType {
  COLA = 'Cola',
  WATER = 'Wasser',
  JUICE = 'Saft',
  OTHER = 'Andere',
}

export interface Beverage {
  additiveIds?: string[];
  alcoholConcentration?: number;
  createdAt: number;
  description?: string;
  id: string;
  maker: string; // Teinacher Gourmet
  origin?: string;
  position: number;
  primaryCategory: BeverageType; // alkoholfrei
  secondaryCategory?:
    | WineType
    | BeerType
    | SchnappsType
    | CoffeeType
    | TeaType
    | AlcoholFreeType; // Säfte
  subtitle?: string; // "Säfte", "Exotische Säfte"
  subtitleInTamil?: string;
  tags?: string[];
  title: string; // "Apfel / Orange / Johanisbeere", "Mango / Maracuja / Litschi / Guave"
  titleInTamil?: string;
  spirituous: boolean;
  quotes: Quotes;
  year?: string;
}

export interface Beverages {
  [beverageId: string]: Beverage;
}

export interface FoodItem {
  additiveIds?: string[];
  createdAt: number;
  description: string;
  id: string;
  position: number;
  spiciness?: 1 | 2 | 3;
  subtitle?: string;
  subtitleInTamil?: string;
  title: string;
  titleInTamil?: string;
  quotes: Quotes;
  vegetarian: 'veg' | 'vegan' | 'veg/an' | false;
}

export interface Food {
  [foodId: string]: FoodItem;
}

export interface Additive {
  id: string; // "konservierungsstoff"
  label: string; // "mit Konservierungsstoff"
  position: number; // 1
}

export interface Additives {
  [additiveId: string]: Additive;
}

// RTO Types

export interface RTOProduct {
  product_id: number;
  product_externalReference?: string;
  product_itemnumber: number;
  product_barcode: number;
  product_name: string;
  product_description: string;
  product_price: number;
  product_priceIncludesVat: boolean;
  product_vat: number;
  product_customPrice: boolean;
  product_customQuantity: boolean;
  product_fav: boolean;
  product_highlight: boolean;
  product_expressMode: boolean;
  product_stock_enabled: boolean;
  product_ingredients_enabled: boolean;
  product_variations_enabled: boolean;
  product_stock_value: number;
  product_stock_unit: string;
  product_stock_reorderLevel: string;
  product_stock_safetyStock: string;
  product_sortIndex: number;
  product_soldOut: boolean;
  product_sideDishOrder: boolean;
  product_discountable: boolean;
  product_accountingCode: string;
  product_colorClass: string;
  productgroup_id: number;
  product_type_id: number;
  product_type: string;
  product_created_at: string;
  product_updated_at: string;
  product_alternativeNameOnReceipts: string;
  product_alternativeNameInPos: string;
}

export interface RTOProductGroup {
  productgroup_id: number;
  productgroup_name: string;
  productgroup_description: string;
  productgroup_shortcut: string;
  productgroup_active: boolean;
  productgroup_parent: number;
  productgroup_sortIndex: number;
  productgroup_accountingCode: number;
  productgroup_type_id: number;
  productgroup_created_at: string;
  productgroup_updated_at: string;
}
