import React, { useState, useEffect } from 'react';
import { PageProps, navigate } from 'gatsby';
import moment from 'moment';
import Calendar from '@soosap/sushi/Calendar';

import Container from 'atoms/Container';
import CarteCreateEdit from '../CarteCreateEdit';
import styles from './LunchMenuCarteEdit.module.scss';

export interface Props extends PageProps {}

const LunchMenuCarteEdit: React.FC<Props> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [calendarWeek, setCalendarWeek] = useState<number>();

  useEffect(() => {
    const calendarWeekQueryString = urlParams.get('calendarWeek');
    const providedCalendarWeek =
      calendarWeekQueryString && parseInt(calendarWeekQueryString);

    if (providedCalendarWeek) {
      const currentCalendarWeek = moment().week();
      const currentYear = moment().year();
      const nextYear = currentYear + 1;

      const applicableYear =
        providedCalendarWeek >= currentCalendarWeek ? currentYear : nextYear;

      const applicableDay = moment()
        .year(applicableYear)
        .week(providedCalendarWeek)
        .weekday(1)
        .toDate();

      setSelectedDay(applicableDay);
    }
  }, []);

  useEffect(() => {
    const calendarWeek = moment(selectedDay).week();
    setCalendarWeek(calendarWeek);
    navigate(`${location.pathname}?calendarWeek=${calendarWeek}`);
  }, [selectedDay]);

  if (!calendarWeek) return null;

  return (
    <Container className={styles[`LunchMenuCarteEdit`]}>
      <Container.Inner>
        <div className={styles[`LunchMenuCarteEdit__calendar`]}>
          <Calendar
            locale="de"
            month={selectedDay}
            selectedDays={[selectedDay]}
            onDayClick={selectedDay => {
              setSelectedDay(selectedDay);
            }}
          />
        </div>
      </Container.Inner>
      <CarteCreateEdit
        carteId={`mittagstisch-kw-${calendarWeek}`}
        title={`KW${calendarWeek}`}
      />
    </Container>
  );
};

export default LunchMenuCarteEdit;
