import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Button from '@soosap/sushi/Button';

import { Additives } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import SimpleList from 'molecules/SimpleList';
import PlusIcon from 'icons/Plus';
import styles from './AdditiveIndex.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

export interface Props {}

const AdditiveIndex: React.FC<Props> = () => {
  const [additives, setAdditives] = useState<Additives>({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${GATSBY_BACKEND_URL}/api/cartes/additives`
      );
      setAdditives(data);
    };

    fetchData();
  }, []);

  return (
    <Container className={styles[`AdditiveIndex`]}>
      <div className={styles[`AdditiveIndex__cta`]}>
        <Link to="/admin/additives/new">
          <Button icon={PlusIcon}>Add</Button>
        </Link>
      </div>
      <Title backTo="/admin">Additives Index</Title>
      <Container.Inner>
        <SimpleList>
          {Object.values(additives)
            .sort((a, b) => a.position - b.position)
            .map(additive => (
              <SimpleList.Item
                key={additive.id}
                to={`/admin/additives/${additive.id}`}
              >
                {additive.position} - {additive.label}
              </SimpleList.Item>
            ))}
        </SimpleList>
      </Container.Inner>
    </Container>
  );
};

export default AdditiveIndex;
