import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';
import 'moment/locale/de';

import Route from 'molecules/Route';
import Layout from 'molecules/Layout';
import AdditiveCreateEdit from 'organisms/admin/AdditiveCreateEdit';
import AdditiveIndex from 'organisms/admin/AdditiveIndex';
import AdminNavigation from 'organisms/admin/AdminNavigation';
import BeverageIndex from 'organisms/admin/BeverageIndex';
import BeverageCreateEdit from 'organisms/admin/BeverageCreateEdit';
import BusinessHoursEdit from 'organisms/admin/BusinessHoursEdit';
import CarteIndex from 'organisms/admin/CarteIndex';
import CarteCreateEdit from 'organisms/admin/CarteCreateEdit';
import BucketCreateEdit from 'organisms/admin/BucketCreateEdit';
import FoodIndex from 'organisms/admin/FoodIndex';
import FoodCreateEdit from 'organisms/admin/FoodCreateEdit';
import GiftCardIndex from 'organisms/admin/GiftCardIndex';
import GiftCardEdit from 'organisms/admin/GiftCardEdit';
import HolidayIndex from 'organisms/admin/HolidayIndex';
import HolidayCreateEdit from 'organisms/admin/HolidayCreateEdit';
import OfferCreateEdit from 'organisms/admin/OfferCreateEdit';
import CarteTypeIndex from 'organisms/admin/CarteTypeIndex';
import LunchMenuCarteEdit from 'organisms/admin/LunchMenuCarteEdit';
import NoticeEdit from 'organisms/admin/NoticeEdit';
import styles from './AdminPage.module.scss';

export const ADMIN_QUERY = graphql`
  query AdminQuery {
    logo: file(relativePath: { eq: "logos/thamarai-admin-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
}

export interface Props {}

const AdminPage: React.FC<Props> = () => {
  const { logo } = useStaticQuery<Data>(ADMIN_QUERY);

  return (
    <Layout
      header={() => (
        <Layout.Header
          title="THAMARAI Administration"
          image={logo.childImageSharp}
          alt="THAMARAI Admin Exklusives Logo"
          to="/admin"
        />
      )}
    >
      {typeof window !== 'undefined' && (
        <div className={styles[`AdminPage`]}>
          <Route.Router>
            <Route path="/admin" component={AdminNavigation} />

            {/* Öffnungszeiten */}
            <Route
              path="/admin/hours/:hoursType"
              component={BusinessHoursEdit}
            />

            {/* Carte */}
            <Route path="/admin/additives" component={AdditiveIndex} />
            <Route path="/admin/additives/new" component={AdditiveCreateEdit} />
            <Route
              path="/admin/additives/:additiveId"
              component={AdditiveCreateEdit}
            />
            <Route path="/admin/food" component={FoodIndex} />
            <Route path="/admin/food/new" component={FoodCreateEdit} />
            <Route path="/admin/food/:foodId" component={FoodCreateEdit} />
            <Route path="/admin/beverages" component={BeverageIndex} />
            <Route path="/admin/beverages/new" component={BeverageCreateEdit} />
            <Route
              path="/admin/beverages/:beverageId"
              component={BeverageCreateEdit}
            />
            <Route path="/admin/cartes" component={CarteIndex} />
            <Route path="/admin/cartes/new" component={CarteCreateEdit} />
            <Route
              path="/admin/cartes/mittagstisch"
              component={LunchMenuCarteEdit as any}
            />
            <Route
              path={`/admin/cartes/types/:carteType`}
              component={CarteTypeIndex}
            />
            <Route
              path="/admin/cartes/:carteId/buckets/new"
              component={BucketCreateEdit}
            />
            <Route
              path="/admin/cartes/:carteId/buckets/:bucketId"
              component={BucketCreateEdit}
            />
            <Route
              path="/admin/cartes/:carteId/offers/new"
              component={OfferCreateEdit}
            />
            <Route
              path="/admin/cartes/:carteId/offers/:bucketId/:offerId"
              component={OfferCreateEdit}
            />
            <Route path="/admin/cartes/:carteId" component={CarteCreateEdit} />

            {/* Holidays */}
            <Route path="/admin/holidays" component={HolidayIndex} />
            <Route path="/admin/holidays/new" component={HolidayCreateEdit} />
            <Route
              path="/admin/holidays/:holidayId"
              component={HolidayCreateEdit}
            />

            {/* Gutscheine */}
            <Route path="/admin/giftcards" component={GiftCardIndex} />
            <Route
              path="/admin/giftcards/:giftCardCode"
              component={GiftCardEdit}
            />

            {/* Notice */}
            <Route path="/admin/notice" component={NoticeEdit} />
          </Route.Router>
        </div>
      )}
    </Layout>
  );
};

export default AdminPage;
