import React from 'react';

export interface Props {}

const HolidayCreateEdit: React.FC<Props> = () => {
  return (
    <div className={`HolidayCreateEdit`}>
      <span>HolidayCreateEdit</span>
    </div>
  );
};

export default HolidayCreateEdit;
