import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from '@reach/router';
import Empty from '@soosap/sushi/Empty';

import { Cartes, Carte } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import SimpleList from 'molecules/SimpleList';
import Title from 'atoms/Title';
import styles from './CarteTypeIndex.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  carteType: string;
}

export interface Props {}

const CarteTypeIndex: React.FC<Props> = () => {
  const params: Params = useParams();
  const [cartes, setCartes] = useState<Carte[]>();

  useEffect(() => {
    const fetchData = async () => {
      if (params.carteType) {
        const { data: cartes = {} } = await axios.get<Cartes>(
          `${GATSBY_BACKEND_URL}/api/cartes/types/${params.carteType}`
        );

        setCartes(Object.values(cartes));
      }
    };

    fetchData();
  }, []);

  if (cartes === undefined) return null;

  return (
    <Container className={styles[`CarteTypeIndex`]}>
      <Title backTo="/admin">{params.carteType} Index</Title>
      <Container.Inner>
        {cartes.length === 0 ? (
          <Empty>There are currently no cartes under this section</Empty>
        ) : (
          <SimpleList>
            {cartes
              .sort((a, b) => a.position - b.position)
              .map(carte => (
                <SimpleList.Item
                  key={carte.id}
                  to={`/admin/cartes/${carte.id}`}
                >
                  {carte.title}
                </SimpleList.Item>
              ))}
          </SimpleList>
        )}
      </Container.Inner>
    </Container>
  );
};

export default CarteTypeIndex;
