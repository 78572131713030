import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Button from '@soosap/sushi/Button';

import { Food } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import SimpleList from 'molecules/SimpleList';
import Title from 'atoms/Title';
import PlusIcon from 'icons/Plus';
import styles from './FoodIndex.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

export interface Props {}

const FoodIndex: React.FC<Props> = () => {
  const [foodItems, setFoodItems] = useState<Food>({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${GATSBY_BACKEND_URL}/api/cartes/food`);
      setFoodItems(data);
    };

    fetchData();
  }, []);

  return (
    <Container className={styles[`FoodIndex`]}>
      <div className={styles[`FoodIndex__cta`]}>
        <Link to="/admin/food/new">
          <Button icon={PlusIcon}>Add</Button>
        </Link>
      </div>
      <Title backTo="/admin">Food Index</Title>
      <Container.Inner>
        <SimpleList>
          {Object.values(foodItems)
            .sort((a, b) => a.position - b.position)
            .map(foodItem => (
              <SimpleList.Item
                key={foodItem.id}
                to={`/admin/food/${foodItem.id}`}
              >
                {foodItem.title}
                {foodItem.subtitle ? ` - ${foodItem.subtitle}` : ''}
              </SimpleList.Item>
            ))}
        </SimpleList>
      </Container.Inner>
    </Container>
  );
};

export default FoodIndex;
