import React from 'react';

export interface Props {}

const HolidayIndex: React.FC<Props> = () => {
  return (
    <div className={`HolidayIndex`}>
      <span>HolidayIndex</span>
    </div>
  );
};

export default HolidayIndex;
