import React, { useState, useEffect } from 'react';
import slugify from 'lodash.kebabcase';
import axios from 'axios';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';
import arrayMutators from 'final-form-arrays';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Form, { FormTheme } from '@soosap/sushi/Form';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import NumberField from '@soosap/sushi/Form/NumberField';
import RatingField from '@soosap/sushi/Form/RatingField';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';

import { FoodItem, Additives, Quote } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import WarningIcon from 'icons/Warning';
import ChiliIcon from 'icons/Chili';
import QuotesFieldArray from 'organisms/admin/QuotesFieldArray';
import styles from './FoodCreateEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  foodId?: string;
}

type FormValues = Pick<
  FoodItem,
  'title' | 'titleInTamil' | 'description' | 'vegetarian' | 'position'
> & {
  quotes: Quote[];
};

export interface Props {}

const FoodCreateEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [additiveOptions, setAdditiveOptions] = useState<SelectOption[]>();

  useEffect(() => {
    const fetchData = async () => {
      const { data: additives } = await axios.get<Additives>(
        `${GATSBY_BACKEND_URL}/api/cartes/additives`
      );

      const options: SelectOption[] = Object.values(additives).map(
        additive => ({
          label: additive.id,
          value: additive.id,
        })
      );

      setAdditiveOptions(options);

      if (params.foodId) {
        const { data } = await axios.get<FoodItem>(
          `${GATSBY_BACKEND_URL}/api/cartes/food/${params.foodId}`
        );

        setInitialValues({
          ...data,
          quotes: data.quotes ? Object.values(data.quotes) : [],
        });
      }
    };

    fetchData();
  }, []);

  const onDelete = async () => {
    const { data: submitErrors } = await axios.delete(
      `${GATSBY_BACKEND_URL}/api/cartes/food/${params.foodId}`
    );

    if (submitErrors) return submitErrors;

    addToast(`Delete erfolgreich`);
    navigate('/admin/food');
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const foodItem = {
        ...values,
        quotes: values.quotes.reduce((prev, curr) => {
          const quoteId = slugify(curr.description);
          return { ...prev, [quoteId]: { ...curr, id: quoteId } };
        }, {}),
        id: params.foodId,
      };

      const { data: submitErrors } = params.foodId
        ? await axios.patch(
            `${GATSBY_BACKEND_URL}/api/cartes/food/${params.foodId}`,
            foodItem
          )
        : await axios.post(`${GATSBY_BACKEND_URL}/api/cartes/food`, foodItem);

      if (submitErrors) return submitErrors;

      addToast(params.foodId ? 'Update erfolgreich' : 'Create erfolgreich');
      navigate('/admin/food');
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (!additiveOptions) return null;
  if (params.foodId && !initialValues) return null;

  return (
    <Container className={styles[`FoodCreateEdit`]}>
      <Title backTo="/admin/food">
        Food {params.foodId ? 'Edit' : 'Create'}
      </Title>
      <Container.Inner>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          render={({ handleSubmit, form, invalid, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FieldSet className={styles[`FoodCreateEdit__fieldset`]}>
                  <TextField
                    name="title"
                    required
                    requiredError="Pflichtfeld"
                    label="Titel"
                    placeholder="z.B. Butter Chicken"
                    disabled={params.foodId ? true : false}
                  />
                  <TextField
                    name="titleInTamil"
                    required
                    requiredError="Pflichtfeld"
                    label="Titel auf Tamil"
                    placeholder="z.B. வெண்ணெய் கோழி"
                  />
                  <TextField
                    name="subtitle"
                    label="Subtitel"
                    placeholder="optional | z.B. Spezialität des Hauses"
                  />
                  <TextField
                    name="subtitleInTamil"
                    label="Subtitel auf Tamil"
                    placeholder="optional | z.B. சிறப்பு"
                  />
                  <TextAreaField
                    name="description"
                    required
                    requiredError="Pflichtfeld"
                    label="Beschreibung"
                    placeholder="z.B. Saftige Hähnchenbrustfiletstreifen mit leckeren Gewürzen und Cashewnüssen serviert in einer herzhaften Tomaten - Sahne - Sauce"
                  />
                  <SelectField
                    name="vegetarian"
                    label="Vegetarisch"
                    initialValue="false"
                    placeholder="Vegetarisch präzisieren..."
                    required
                    requiredError="Pflichtfeld"
                    options={[
                      { label: 'NEIN', value: false },
                      { label: 'VEG', value: 'veg' },
                      { label: 'VEG/AN', value: 'veg/an' },
                      { label: 'VEGAN', value: 'vegan' },
                    ]}
                  />
                  <NumberField
                    name="position"
                    required
                    requiredError="Pflichtfeld"
                    label="Position"
                    placeholder="z.B. 1"
                    initialValue={1}
                  />
                  <RatingField
                    name="spiciness"
                    max={3}
                    label="Schärfegrad"
                    icon={ChiliIcon}
                    theme={FormTheme.ERROR}
                  />
                  <SelectField
                    name="additiveIds"
                    label="Zusatzstoffe"
                    placeholder="Zusatzstoffe auswählen"
                    multiple
                    options={additiveOptions}
                  />
                  <QuotesFieldArray
                    isSuggestion
                    initialValue={
                      initialValues?.quotes || [
                        { id: '1x', price: 0, description: '1x', position: 1 },
                      ]
                    }
                  />
                  <Button.Group>
                    <Button
                      type="submit"
                      disabled={invalid || submitting}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {params.foodId ? 'Edit' : 'Create'}
                    </Button>
                    <Button
                      onClick={() => {
                        const quotes = form.getFieldState('quotes');
                        form.mutators.push('quotes', {
                          position: 1 + quotes?.value.length || 0,
                        });
                      }}
                    >
                      Add Quote
                    </Button>
                    <Button
                      disabled={submitting}
                      borderless
                      theme={ButtonTheme.ERROR}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  </Button.Group>
                </FieldSet>
              </form>
            );
          }}
        />
      </Container.Inner>
    </Container>
  );
};

export default FoodCreateEdit;
