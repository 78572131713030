import React from 'react';
import { Link } from 'gatsby';
import Button from '@soosap/sushi/Button';
import axios from 'axios';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Container from 'atoms/Container';
import GroceryList from 'molecules/GroceryList';
import CheckIcon from 'icons/Check';
import styles from './AdminNavigation.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

export interface Props {}

const AdminNavigation: React.FC<Props> = () => {
  const { addToast } = useToasts();

  const triggerBuild = async (buildConfigurationId: string) => {
    try {
      await axios.post(`${GATSBY_BACKEND_URL}/api/ci/trigger`, {
        buildConfigurationId,
      });
      addToast(`Build triggered! Wait ~10min`, {
        theme: ToastTheme.SUCCESS,
        autoDismiss: false,
        icon: CheckIcon,
      });
    } catch (e) {
      throw new Error(e.message);
    }
  };

  return (
    <Container>
      <div className={styles[`AdminNavigation`]}>
        <div className={styles[`AdminNavigation__deploy`]}>
          <div className={styles[`AdminNavigation__deploy-inner`]}>
            <div className={styles[`AdminNavigation__deploy-label`]}>
              Trigger build:
            </div>
            <Button.Group>
              <Button
                onClick={() =>
                  triggerBuild('Thamarai_Website_Staging_DockerImageNoCache')
                }
              >
                Staging
              </Button>
              <Button
                onClick={() =>
                  triggerBuild('Thamarai_Website_Production_DockerImageNoCache')
                }
              >
                Production
              </Button>
            </Button.Group>
          </div>
        </div>
        <div className={styles[`AdminNavigation__grid`]}>
          <div>
            <GroceryList title="Öffnungszeiten">
              <GroceryList.Item>
                <Link to="/admin/hours/dineIn">Dine In</Link>
              </GroceryList.Item>
              <GroceryList.Item>
                <Link to="/admin/hours/takeAway">Take Away</Link>
              </GroceryList.Item>
              <GroceryList.Item>
                <Link to="/admin/hours/delivery">Delivery</Link>
              </GroceryList.Item>
              <GroceryList.Item>
                <Link to="/admin/holidays">Holiday Index</Link>
              </GroceryList.Item>
              <GroceryList.Item>
                <Link to="/admin/holidays/new">Holiday Create</Link>
              </GroceryList.Item>
            </GroceryList>

            <GroceryList title="Other">
              <GroceryList.Item>
                <Link to="/admin/notice">Notice</Link>
              </GroceryList.Item>
              <GroceryList.Item>
                <Link to="/admin/giftcards">Giftcards</Link>
              </GroceryList.Item>
            </GroceryList>
          </div>

          <GroceryList title="Carte">
            <GroceryList.Item>
              <Link to="/admin/cartes">Carte Index</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/cartes/new">Carte Create</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/food">Food Index</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/food/new">Food Create</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/beverages">Beverage Index</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/beverages/new">Beverage Create</Link>
            </GroceryList.Item>
            <GroceryList.Item>
              <Link to="/admin/additives">Additive Index</Link>
            </GroceryList.Item>
          </GroceryList>
        </div>
      </div>
    </Container>
  );
};

export default AdminNavigation;
