import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { navigateTo } from 'gatsby';
import { useParams } from '@reach/router';
import slugify from 'lodash.kebabcase';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Form from '@soosap/sushi/Form';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import CheckboxField from '@soosap/sushi/Form/CheckboxField';
import NumberField from '@soosap/sushi/Form/NumberField';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';
import CreatableField from '@soosap/sushi/Form/CreatableField';
import styles from './BeverageCreateEdit.module.scss';

import Container from 'atoms/Container';
import Title from 'atoms/Title';
import WarningIcon from 'icons/Warning';
import {
  Beverage,
  Additives,
  Quote,
  BeverageType,
  WineType,
  BeerType,
  CoffeeType,
  SchnappsType,
  TeaType,
  AlcoholFreeType,
} from 'server/src/app/carte/types';
import QuotesFieldArray from 'organisms/admin/QuotesFieldArray';
import AlcoholConcentration from './components/AlcoholConcentration';
import SecondaryCategory from './components/SecondaryCategory';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  beverageId?: string;
}

type FormValues = Pick<
  Beverage,
  | 'title'
  | 'titleInTamil'
  | 'subtitle'
  | 'subtitleInTamil'
  | 'description'
  | 'additiveIds'
  | 'alcoholConcentration'
  | 'maker'
  | 'origin'
  | 'spirituous'
  | 'year'
  | 'position'
  | 'primaryCategory'
  | 'secondaryCategory'
  | 'tags'
> & {
  quotes: Quote[];
};

const calculator = createDecorator({
  field: 'primaryCategory',
  updates: {
    secondaryCategory: (primaryCategory, allValues, prevValues) => {
      const initialRender = prevValues
        ? Object.keys(prevValues).length === 0
        : true;

      return initialRender
        ? (allValues as FormValues).secondaryCategory
        : undefined;
    },
  },
});

export interface Props {}

const BeverageCreateEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [additiveOptions, setAdditiveOptions] = useState<SelectOption[]>();
  const [beverageTypeOptions] = useState<SelectOption[]>(
    Object.entries(BeverageType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [wineOptions] = useState<SelectOption[]>(
    Object.entries(WineType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [beerOptions] = useState<SelectOption[]>(
    Object.entries(BeerType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [coffeeOptions] = useState<SelectOption[]>(
    Object.entries(CoffeeType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [schnappsOptions] = useState<SelectOption[]>(
    Object.entries(SchnappsType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [teaOptions] = useState<SelectOption[]>(
    Object.entries(TeaType).map(e => ({ label: e[1], value: e[1] }))
  );
  const [alcoholFreeOptions] = useState<SelectOption[]>(
    Object.entries(AlcoholFreeType).map(e => ({ label: e[1], value: e[1] }))
  );

  useEffect(() => {
    const fetchData = async () => {
      const { data: additives } = await axios.get<Additives>(
        `${GATSBY_BACKEND_URL}/api/cartes/additives`
      );

      const options: SelectOption[] = Object.values(additives)
        .sort((a, b) => a.position - b.position)
        .map(additive => ({
          label: `${additive.position}) ${additive.label}`,
          value: additive.id,
        }));

      setAdditiveOptions(options);

      if (params.beverageId) {
        const { data } = await axios.get<Beverage>(
          `${GATSBY_BACKEND_URL}/api/cartes/beverages/${params.beverageId}`
        );

        setInitialValues({
          ...data,
          quotes: data.quotes ? Object.values(data.quotes) : [],
        });
      }
    };

    fetchData();
  }, []);

  const onDelete = async () => {
    const res = await axios.delete(
      `${GATSBY_BACKEND_URL}/api/cartes/beverages/${params.beverageId}`
    );
    addToast(res.data);
    navigateTo('/admin/beverages');
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const beverage: Partial<Beverage> = {
        ...values,
        additiveIds: values.additiveIds || [],
        quotes: values.quotes.reduce((prev, curr) => {
          const quoteId = slugify(curr.description);
          return { ...prev, [quoteId]: { ...curr, id: quoteId } };
        }, {}),
        alcoholConcentration: values.spirituous
          ? values.alcoholConcentration
          : undefined,
        id: params.beverageId,
      };

      const { data: submitErrors } = params.beverageId
        ? await axios.post(
            `${GATSBY_BACKEND_URL}/api/cartes/beverages/${params.beverageId}`,
            beverage
          )
        : await axios.post(
            `${GATSBY_BACKEND_URL}/api/cartes/beverages`,
            beverage
          );

      if (submitErrors) return submitErrors;

      addToast(params.beverageId ? 'Update erfolgreich' : 'Create erfolgreich');
      navigateTo('/admin/beverages');
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (!additiveOptions) return null;
  if (params.beverageId && !initialValues) return null;

  return (
    <Container className={styles[`BeverageCreateEdit`]}>
      <Form
        onSubmit={onSubmit}
        decorators={[calculator]}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({
          handleSubmit,
          form,
          invalid,
          submitting,
          values,
          dirtySinceLastSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Title backTo="/admin/beverages">
                Beverage {params.beverageId ? 'Edit' : 'Create'}
              </Title>
              <Container.Inner>
                <FieldSet className={styles[`BeverageCreateEdit__fieldset`]}>
                  <TextField
                    name="title"
                    required
                    requiredError="Pflichtfeld"
                    label="Titel"
                    placeholder="z.B. Trollinger mit Lemberger"
                    disabled={params.beverageId ? true : false}
                  />
                  <TextField
                    name="titleInTamil"
                    label="Titel auf Tamil"
                    placeholder="z.B. லெம்பர்கருடன் ட்ரோலிங்கர்"
                    collapsible
                    initiallyCollapsed
                  />
                  <TextField
                    name="subtitle"
                    label="Subtitel"
                    placeholder="optional | z.B. Villa Sulmana"
                    disabled={params.beverageId ? true : false}
                  />
                  <TextField
                    name="subtitleInTamil"
                    label="Subtitel auf Tamil"
                    placeholder="optional | z.B. சிறப்பு பதிப்பு"
                    collapsible
                    initiallyCollapsed
                  />
                  <div className={styles[`BeverageCreateEdit__categories`]}>
                    <SelectField
                      name="primaryCategory"
                      label="Primarkategorie"
                      placeholder="z.B. Wein"
                      options={beverageTypeOptions}
                    />
                    <SecondaryCategory
                      key={`SecondaryCategory--${values.secondaryCategory}`}
                      wineOptions={wineOptions}
                      beerOptions={beerOptions}
                      schnappsOptions={schnappsOptions}
                      coffeeOptions={coffeeOptions}
                      teaOptions={teaOptions}
                      alcoholFreeOptions={alcoholFreeOptions}
                    />
                  </div>
                  <TextAreaField
                    name="description"
                    label="Beschreibung"
                    placeholder="z.B. Vollmundiger und kräftiger Charakter mit herzhaft ausgeprägten Fruchtnuancen von Johannisbeeren, Brombeeren und Kirschen"
                  />
                  <TextField
                    name="origin"
                    label="Herkunft"
                    placeholder="optional | z.B. Heilbronner Staufenberg, Württemberg, etc."
                  />
                  <TextField
                    name="maker"
                    label="Produzent"
                    placeholder="optional | z.B. Genossenschaftskellerei Heilbronn"
                  />
                  <NumberField
                    name="year"
                    label="Jahrgang"
                    placeholder="optional | z.B. 2018"
                  />
                  <div className={styles[`BeverageCreateEdit__alcohol`]}>
                    <CheckboxField
                      name="spirituous"
                      className={styles[`BeverageCreateEdit__spirituous`]}
                      label="alkoholhaltig"
                      initialValue={false}
                    />
                  </div>
                  <AlcoholConcentration />
                  <NumberField
                    name="position"
                    required
                    requiredError="Pflichtfeld"
                    label="Position"
                    placeholder="z.B. 1"
                    initialValue={1}
                  />
                  <SelectField
                    name="additiveIds"
                    label="Zusatzstoffe"
                    placeholder="Zusatzstoffe auswählen"
                    multiple
                    options={additiveOptions}
                  />
                  <CreatableField
                    name="tags"
                    label="tags"
                    placeholder="z.B. QbA, halbtrocken, etc."
                    multiple
                  />
                  <QuotesFieldArray
                    isSuggestion
                    initialValue={
                      initialValues?.quotes || [
                        {
                          id: '0-5l',
                          price: 0,
                          description: '0,5l',
                          position: 1,
                        },
                      ]
                    }
                  />
                  <Button.Group>
                    <Button
                      type="submit"
                      disabled={
                        (invalid && !dirtySinceLastSubmit) || submitting
                      }
                      onMouseDown={e => e.preventDefault()}
                    >
                      {params.beverageId ? 'Edit' : 'Create'}
                    </Button>
                    <Button
                      onClick={() => {
                        const quotes = form.getFieldState('quotes');
                        form.mutators.push('quotes', {
                          position: 1 + quotes?.value.length || 0,
                        });
                      }}
                      onMouseDown={e => e.preventDefault()}
                    >
                      Add Quote
                    </Button>
                    <Button
                      disabled={submitting}
                      borderless
                      theme={ButtonTheme.ERROR}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  </Button.Group>
                </FieldSet>
              </Container.Inner>
            </form>
          );
        }}
      />
    </Container>
  );
};

export default BeverageCreateEdit;
