import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from '@reach/router';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import { BusinessHours, BusinessHoursType } from 'server/src/app/opening/types';

import Container from 'atoms/Container';
import Title from 'atoms/Title';
import WarningIcon from 'icons/Warning';
import Form, { FormSize } from '@soosap/sushi/Form';
import FieldSet from '@soosap/sushi/FieldSet';
import ToggleField from '@soosap/sushi/Form/ToggleField';
import TimeField from '@soosap/sushi/Form/TimeField';
import Button from '@soosap/sushi/Button';
import merge from 'lodash.merge';
import styles from './BusinessHoursEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface FormValues {
  mondayLunchActive: boolean;
  mondayLunchFrom: string;
  mondayLunchTo: string;
  mondayDinnerActive: boolean;
  mondayDinnerFrom: string;
  mondayDinnerTo: string;
  tuesdayLunchActive: boolean;
  tuesdayLunchFrom: string;
  tuesdayLunchTo: string;
  tuesdayDinnerActive: boolean;
  tuesdayDinnerFrom: string;
  tuesdayDinnerTo: string;
  wednesdayLunchActive: boolean;
  wednesdayLunchFrom: string;
  wednesdayLunchTo: string;
  wednesdayDinnerActive: boolean;
  wednesdayDinnerFrom: string;
  wednesdayDinnerTo: string;
  thursdayLunchActive: boolean;
  thursdayLunchFrom: string;
  thursdayLunchTo: string;
  thursdayDinnerActive: boolean;
  thursdayDinnerFrom: string;
  thursdayDinnerTo: string;
  fridayLunchActive: boolean;
  fridayLunchFrom: string;
  fridayLunchTo: string;
  fridayDinnerActive: boolean;
  fridayDinnerFrom: string;
  fridayDinnerTo: string;
  saturdayLunchActive: boolean;
  saturdayLunchFrom: string;
  saturdayLunchTo: string;
  saturdayDinnerActive: boolean;
  saturdayDinnerFrom: string;
  saturdayDinnerTo: string;
  sundayLunchActive: boolean;
  sundayLunchFrom: string;
  sundayLunchTo: string;
  sundayDinnerActive: boolean;
  sundayDinnerFrom: string;
  sundayDinnerTo: string;
}

export interface Props {}

export interface Params {
  hoursType: BusinessHoursType;
}

const BusinessHoursEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const { hoursType }: Params = useParams();
  const [initialValues, setInitialValues] = useState<Partial<FormValues>>();
  const [businessHours, setBusinessHours] = useState<BusinessHours>({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get<BusinessHours>(
        `${GATSBY_BACKEND_URL}/api/opening/hours/${hoursType}`
      );

      const initialValues = Object.values(data).reduce((prev, curr) => {
        return {
          ...prev,
          [`${curr.id}LunchActive`]: curr.lunchActive,
          [`${curr.id}LunchFrom`]: curr.lunchFrom,
          [`${curr.id}LunchTo`]: curr.lunchTo,
          [`${curr.id}DinnerActive`]: curr.dinnerActive,
          [`${curr.id}DinnerFrom`]: curr.dinnerFrom,
          [`${curr.id}DinnerTo`]: curr.dinnerTo,
        };
      }, {} as FormValues);

      setInitialValues(initialValues);
      setBusinessHours(data);
    };

    fetchData();
  }, []);

  const onSubmit = async (values: FormValues) => {
    try {
      const hours: BusinessHours = Object.values(businessHours).reduce(
        (prev, curr) => {
          return {
            ...prev,
            [`${curr.id}`]: {
              lunchActive: values[`${curr.id}LunchActive`],
              lunchFrom: values[`${curr.id}LunchFrom`],
              lunchTo: values[`${curr.id}LunchTo`],
              dinnerActive: values[`${curr.id}DinnerActive`],
              dinnerFrom: values[`${curr.id}DinnerFrom`],
              dinnerTo: values[`${curr.id}DinnerTo`],
            },
          };
        },
        {}
      );

      const updatedHours = merge(businessHours, hours);

      const res = await axios.post(`${GATSBY_BACKEND_URL}/api/opening/hours`, {
        hoursType,
        hours: updatedHours,
      });

      addToast(res.data);
    } catch (e) {
      addToast(`Fehler! Bitte anrufen ☎ 07131 405 11 70`, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (!initialValues) return null;

  return (
    <Container>
      <div className={styles[`BusinessHoursEdit`]}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className={styles[`BusinessHoursEdit__form`]}
              >
                <Title
                  className={styles[`BusinessHoursEdit__title`]}
                  backTo="/admin"
                >
                  Öffnungszeiten
                </Title>
                <Container.Inner>
                  <FieldSet
                    title="Montag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="mondayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="mondayLunchFrom"
                      disabled={!values.mondayLunchActive}
                    />
                    <TimeField
                      name="mondayLunchTo"
                      disabled={!values.mondayLunchActive}
                    />
                    <ToggleField
                      name="mondayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="mondayDinnerFrom"
                      disabled={!values.mondayDinnerActive}
                    />
                    <TimeField
                      name="mondayDinnerTo"
                      disabled={!values.mondayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Dienstag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="tuesdayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="tuesdayLunchFrom"
                      disabled={!values.tuesdayLunchActive}
                    />
                    <TimeField
                      name="tuesdayLunchTo"
                      disabled={!values.tuesdayLunchActive}
                    />
                    <ToggleField
                      name="tuesdayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="tuesdayDinnerFrom"
                      disabled={!values.tuesdayDinnerActive}
                    />
                    <TimeField
                      name="tuesdayDinnerTo"
                      disabled={!values.tuesdayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Mittwoch"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="wednesdayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="wednesdayLunchFrom"
                      disabled={!values.wednesdayLunchActive}
                    />
                    <TimeField
                      name="wednesdayLunchTo"
                      disabled={!values.wednesdayLunchActive}
                    />
                    <ToggleField
                      name="wednesdayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="wednesdayDinnerFrom"
                      disabled={!values.wednesdayDinnerActive}
                    />
                    <TimeField
                      name="wednesdayDinnerTo"
                      disabled={!values.wednesdayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Donnerstag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="thursdayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="thursdayLunchFrom"
                      disabled={!values.thursdayLunchActive}
                    />
                    <TimeField
                      name="thursdayLunchTo"
                      disabled={!values.thursdayLunchActive}
                    />
                    <ToggleField
                      name="thursdayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="thursdayDinnerFrom"
                      disabled={!values.thursdayDinnerActive}
                    />
                    <TimeField
                      name="thursdayDinnerTo"
                      disabled={!values.thursdayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Freitag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="fridayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="fridayLunchFrom"
                      disabled={!values.fridayLunchActive}
                    />
                    <TimeField
                      name="fridayLunchTo"
                      disabled={!values.fridayLunchActive}
                    />
                    <ToggleField
                      name="fridayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="fridayDinnerFrom"
                      disabled={!values.fridayDinnerActive}
                    />
                    <TimeField
                      name="fridayDinnerTo"
                      disabled={!values.fridayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Samstag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="saturdayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="saturdayLunchFrom"
                      disabled={!values.saturdayLunchActive}
                    />
                    <TimeField
                      name="saturdayLunchTo"
                      disabled={!values.saturdayLunchActive}
                    />
                    <ToggleField
                      name="saturdayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="saturdayDinnerFrom"
                      disabled={!values.saturdayDinnerActive}
                    />
                    <TimeField
                      name="saturdayDinnerTo"
                      disabled={!values.saturdayDinnerActive}
                    />
                  </FieldSet>

                  <FieldSet
                    title="Sonntag"
                    classNameInner={styles[`BusinessHoursEdit__day`]}
                  >
                    <div />
                    <div className={styles[`BusinessHoursEdit__label`]}>
                      From
                    </div>
                    <div className={styles[`BusinessHoursEdit__label`]}>To</div>
                    <ToggleField
                      name="sundayLunchActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="sundayLunchFrom"
                      disabled={!values.sundayLunchActive}
                    />
                    <TimeField
                      name="sundayLunchTo"
                      disabled={!values.sundayLunchActive}
                    />
                    <ToggleField
                      name="sundayDinnerActive"
                      className={styles[`BusinessHoursEdit__toggle`]}
                      size={FormSize.SMALL}
                    />
                    <TimeField
                      name="sundayDinnerFrom"
                      disabled={!values.sundayDinnerActive}
                    />
                    <TimeField
                      name="sundayDinnerTo"
                      disabled={!values.sundayDinnerActive}
                    />
                  </FieldSet>
                  <Button
                    primary
                    type="submit"
                    onMouseDown={e => e.preventDefault()}
                  >
                    Submit
                  </Button>
                </Container.Inner>
              </form>
            );
          }}
        />
      </div>
    </Container>
  );
};

export default BusinessHoursEdit;
