import React from 'react';
import { useField } from 'react-final-form';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';

export interface Props {
  wineOptions: SelectOption[];
  beerOptions: SelectOption[];
  schnappsOptions: SelectOption[];
  coffeeOptions: SelectOption[];
  teaOptions: SelectOption[];
  alcoholFreeOptions: SelectOption[];
}

const SecondaryCategory: React.FC<Props> = ({
  wineOptions: Wein,
  beerOptions: Bier,
  schnappsOptions: Schnaps,
  coffeeOptions: Kaffee,
  teaOptions: Tee,
  alcoholFreeOptions: Alkoholfrei,
}) => {
  const options = { Wein, Bier, Schnaps, Kaffee, Tee, Alkoholfrei };
  const primaryCategoryField = useField('primaryCategory');

  return options[primaryCategoryField.input.value] ? (
    <SelectField
      name="secondaryCategory"
      label="Sekundarkategorie"
      placeholder={`z.B. ${options[primaryCategoryField.input.value][0].label}`}
      options={options[primaryCategoryField.input.value]}
    />
  ) : null;
};

export default SecondaryCategory;
