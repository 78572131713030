import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useToasts } from '@soosap/sushi/Toast';
import Form from '@soosap/sushi/Form/Form';
import ToggleField from '@soosap/sushi/Form/ToggleField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import Button from '@soosap/sushi/Button';

import { Notice } from 'server/src/app/notice/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import styles from './NoticeEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface FormValues {
  active: boolean;
  message: string;
}

export interface Props {}

const NoticeEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const [notice, setNotice] = useState<Notice>();

  useEffect(() => {
    const fetchData = async () => {
      const { data: notice } = await axios.get<Notice>(
        `${GATSBY_BACKEND_URL}/api/notices/default`,
        {
          auth: {
            username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
            password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
          },
        }
      );

      setNotice(notice);
    };

    fetchData();
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { data: submitErrors } = await axios.patch(
      `${GATSBY_BACKEND_URL}/api/notices/default`,
      values,
      {
        auth: {
          username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
          password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
        },
      }
    );

    if (submitErrors) return submitErrors;
    addToast('Update erfolgreich');
  };

  return (
    <Container className={styles[`NoticeEdit`]}>
      <Title backTo="/admin">Notice Edit</Title>
      <Container.Inner>
        {notice ? (
          <Form
            initialValues={notice}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ToggleField name="active" label="Active" />
                <TextAreaField
                  name="message"
                  label="Message"
                  placeholder="z.B: Vom 12.10. - 24.10. bleibt unser Restaurant geschlossen"
                />
                <Button type="submit" onMouseDown={e => e.preventDefault()}>
                  Edit
                </Button>
              </form>
            )}
          />
        ) : null}
      </Container.Inner>
    </Container>
  );
};

export default NoticeEdit;
