import React from 'react';

import ListItem, { Props as ListItemProps } from './components/ListItem';
import styles from './SimpleList.module.scss';

interface FC<P = {}> extends React.FC<P> {
  Item: React.FC<ListItemProps>;
}

export interface Props {}

const SimpleList: FC<Props> = ({ children }) => {
  return <ul className={styles[`SimpleList`]}>{children}</ul>;
};

SimpleList.Item = ListItem;

export default SimpleList;
