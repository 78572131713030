import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import Button from '@soosap/sushi/Button';

import { Beverages } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import SimpleList from 'molecules/SimpleList';
import Title from 'atoms/Title';
import PlusIcon from 'icons/Plus';
import styles from './BeverageIndex.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

export interface Props {}

const BeverageIndex: React.FC<Props> = () => {
  const [beverages, setBeverages] = useState<Beverages>({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${GATSBY_BACKEND_URL}/api/cartes/beverages`
      );
      setBeverages(data);
    };

    fetchData();
  }, []);

  return (
    <Container className={styles[`BeverageIndex`]}>
      <div className={styles[`BeverageIndex__cta`]}>
        <Link to="/admin/beverages/new">
          <Button icon={PlusIcon}>Add</Button>
        </Link>
      </div>
      <Title backTo="/admin">Beverage Index</Title>
      <Container.Inner>
        <SimpleList>
          {Object.values(beverages)
            .sort((a, b) => a.position - b.position)
            .map(beverage => (
              <SimpleList.Item
                key={beverage.id}
                to={`/admin/beverages/${beverage.id}`}
              >
                {beverage.subtitle && (
                  <span className={styles[`BeverageIndex__subtitle`]}>
                    {beverage.subtitle}
                  </span>
                )}
                <span className={styles[`BeverageIndex__title`]}>
                  {beverage.title}
                </span>
              </SimpleList.Item>
            ))}
        </SimpleList>
      </Container.Inner>
    </Container>
  );
};

export default BeverageIndex;
