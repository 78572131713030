import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import slugify from 'lodash.kebabcase';
import { useParams } from '@reach/router';
import axios from 'axios';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Icon, { IconTheme } from '@soosap/sushi/Icon';
import Button from '@soosap/sushi/Button';
import Empty from '@soosap/sushi/Empty';
import Form from '@soosap/sushi/Form';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import NumberField from '@soosap/sushi/Form/NumberField';
import CheckboxField from '@soosap/sushi/Form/CheckboxField';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import SimpleList from 'molecules/SimpleList';
import WarningIcon from 'icons/Warning';
import PlusIcon from 'icons/Plus';
import CheckIcon from 'icons/Check';
import {
  Carte,
  CarteType,
  ProductType,
  Offer,
} from 'server/src/app/carte/types';
import styles from './CarteCreateEdit.module.scss';
import Spinner from '@soosap/sushi/Spinner';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  carteId?: string;
}

type FormValues = Omit<Carte, 'id' | 'createdAt'>;

export interface Props {
  carteId?: string;
  title?: string;
}

const CarteCreateEdit: React.FC<Props> = props => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [carte, setCarte] = useState<FormValues>();
  const [foodOffers, setFoodOffers] = useState<Offer[]>([]);
  const [beverageOffers, setBeverageOffers] = useState<Offer[]>([]);
  const [carteTypeOptions] = useState<SelectOption[]>(
    Object.entries(CarteType).map(e => ({ label: e[0], value: e[1] }))
  );

  const carteId = params.carteId || props.carteId;

  useEffect(() => {
    const fetchData = async () => {
      if (carteId) {
        const { data: carte } = await axios.get<Carte>(
          `${GATSBY_BACKEND_URL}/api/cartes/${carteId}`
        );

        const foodOffers: Offer[] = Object.values(carte.buckets || {}).reduce(
          (prev, curr) => {
            const foodOffers = Object.values(curr.offers || {}).filter(
              offer => offer.productType === ProductType.FOOD_ITEM
            );

            return [...prev, ...foodOffers];
          },
          [] as Offer[]
        );

        const beverageOffers: Offer[] = Object.values(
          carte.buckets || {}
        ).reduce((prev, curr) => {
          const beverageOffers = Object.values(curr.offers || {}).filter(
            offer => offer.productType === ProductType.BEVERAGE
          );

          return [...prev, ...beverageOffers];
        }, [] as Offer[]);

        setFoodOffers(foodOffers);
        setBeverageOffers(beverageOffers);
        setCarte(carte);
      }
    };

    fetchData();
  }, [carteId]);

  const onSubmit = async (values: FormValues) => {
    try {
      const updatedCarte: Omit<Carte, 'id' | 'createdAt'> = carteId
        ? { ...carte, ...values }
        : values;

      const { data: submitErrors } = carteId
        ? await axios.post(
            `${GATSBY_BACKEND_URL}/api/cartes/${carteId}`,
            updatedCarte
          )
        : await axios.post(`${GATSBY_BACKEND_URL}/api/cartes`, updatedCarte);

      if (submitErrors) return submitErrors;

      setCarte(updatedCarte);
      addToast(carteId ? 'Update erfolgreich' : 'Create erfolgreich');
      navigate(`/admin/cartes/${slugify(values.title)}`);
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (carteId && !carte) return null;

  return (
    <Container className={styles[`CarteCreateEdit`]}>
      <Form
        onSubmit={onSubmit}
        initialValues={carte}
        render={({
          handleSubmit,
          invalid,
          submitting,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Title backTo="/admin/cartes">
              {props.title
                ? props.title
                : `Carte ${carteId ? 'Edit' : 'Create'}`}
            </Title>
            <Container.Inner>
              <SelectField
                name="type"
                label="Type"
                placeholder="z.B. Geschlossene Gesellschaft"
                options={carteTypeOptions}
                required
                requiredError="Pflichtfeld"
                disabled={carteId ? true : false}
              />
              <TextField
                name="title"
                required
                requiredError="Pflichtfeld"
                label="Titel"
                placeholder="z.B. Sommerkarte, Fabian's 25th Birthday, etc."
                disabled={carteId ? true : false}
              />
              <TextField
                name="titelInTamil"
                label="Titel in Tamil"
                placeholder={`optional | z.B. "கோடை பட்டியல்", "பாபியான் 25வது பிறந்தநாள்", etc.`}
                collapsible
                initiallyCollapsed={carte?.titleInTamil ? false : true}
              />
              <TextField
                name="subtitle"
                label="Subtitel"
                placeholder={`optional | z.B. "Family & Friends", "24. August 2020", etc.`}
                collapsible
                initiallyCollapsed={carte?.subtitle ? false : true}
              />
              <TextField
                name="subtitelInTamil"
                label="Subtitel in Tamil"
                placeholder={`optional | z.B. "குடும்ப நண்பர்கள்", "ஆகஸ்ட் 24, 2020", etc.`}
                collapsible
                initiallyCollapsed={carte?.subtitleInTamil ? false : true}
              />
              <TextAreaField
                name="description"
                label="Beschreibung"
                placeholder={`optional | z.B. "Gemütlich auf der Terrasse sitzen, die warmen Temperaturen genießen und es sich schmecken lassen – so sieht der Sommer aus im THAMARAI Restaurant. Ab sofort gilt unsere Sommerkarte."`}
                collapsible
                initiallyCollapsed={carte?.description ? false : true}
              />
              <NumberField
                name="position"
                required
                requiredError="Pflichtfeld"
                label="Position"
                placeholder="z.B. 1"
                initialValue={1}
              />
              <div>
                <CheckboxField
                  name="shouldDisplayQuotes"
                  label="Preise anzeigen"
                  initialValue={true}
                />
              </div>
              <Button
                type="submit"
                disabled={(invalid && !dirtySinceLastSubmit) || submitting}
                onMouseDown={e => e.preventDefault()}
              >
                {submitting && (
                  <Spinner className={styles[`CarteCreateEdit__spinner`]} />
                )}
                {carteId ? 'Edit' : 'Create'}
              </Button>
            </Container.Inner>
            {carteId && (
              <>
                <Title className={styles[`CarteCreateEdit__food`]}>Food</Title>
                <Container.Inner>
                  <div className={styles[`CarteCreateEdit__section`]}>
                    <Link
                      to={`/admin/cartes/${carteId}/offers/new?productType=${ProductType.FOOD_ITEM}`}
                    >
                      <Button icon={PlusIcon}>Add</Button>
                    </Link>
                    {foodOffers.length > 0 ? (
                      <SimpleList>
                        {foodOffers
                          .sort((a, b) => a.position - b.position)
                          .map(offer => (
                            <SimpleList.Item
                              key={offer.id}
                              to={`/admin/cartes/${carteId}/offers/${offer.bucketId ||
                                'default'}/${offer.id}?productType=${
                                ProductType.FOOD_ITEM
                              }`}
                              title={offer.bucketId}
                              right={
                                offer.active ? (
                                  <Icon
                                    svg={CheckIcon}
                                    theme={IconTheme.SECONDARY}
                                  />
                                ) : null
                              }
                            >
                              {offer.product.title}
                            </SimpleList.Item>
                          ))}
                      </SimpleList>
                    ) : (
                      <Empty className={styles[`CarteCreateEdit__empty`]}>
                        No food offers placed in carte
                      </Empty>
                    )}
                  </div>
                </Container.Inner>

                <Title>Beverages</Title>
                <Container.Inner>
                  <div className={styles[`CarteCreateEdit__section`]}>
                    <Link
                      to={`/admin/cartes/${carteId}/offers/new?productType=${ProductType.BEVERAGE}`}
                    >
                      <Button icon={PlusIcon}>Add</Button>
                    </Link>
                    {beverageOffers.length > 0 ? (
                      <SimpleList>
                        {beverageOffers
                          .sort((a, b) => a.position - b.position)
                          .map(offer => (
                            <SimpleList.Item
                              key={offer.id}
                              to={`/admin/cartes/${carteId}/offers/${offer.bucketId}/${offer.id}?productType=${ProductType.BEVERAGE}`}
                              title={offer.bucketId}
                              right={
                                offer.active ? (
                                  <Icon
                                    svg={CheckIcon}
                                    theme={IconTheme.SECONDARY}
                                  />
                                ) : null
                              }
                            >
                              {offer.product.subtitle
                                ? `${offer.product.subtitle.toUpperCase()} `
                                : ''}
                              {offer.product.title}
                            </SimpleList.Item>
                          ))}
                      </SimpleList>
                    ) : (
                      <Empty className={styles[`CarteCreateEdit__empty`]}>
                        No beverage offers placed in carte
                      </Empty>
                    )}
                  </div>
                </Container.Inner>

                <Title>Buckets</Title>
                <Container.Inner>
                  <div className={styles[`CarteCreateEdit__section`]}>
                    <Link to={`/admin/cartes/${carteId}/buckets/new`}>
                      <Button icon={PlusIcon}>Add</Button>
                    </Link>
                    {carte?.buckets ? (
                      <SimpleList>
                        {Object.values(carte!.buckets)
                          .sort((a, b) => a.position - b.position)
                          .map(bucket => (
                            <SimpleList.Item
                              key={bucket.id || 'default'}
                              to={`/admin/cartes/${carteId}/buckets/${bucket.id}`}
                            >
                              {bucket.title || 'Default'}
                            </SimpleList.Item>
                          ))}
                      </SimpleList>
                    ) : (
                      <Empty className={styles[`CarteCreateEdit__empty`]}>
                        Buckets are optional. If you do not create one all
                        offers will be placed in the "default" bucket
                      </Empty>
                    )}
                  </div>
                </Container.Inner>
              </>
            )}
          </form>
        )}
      />
    </Container>
  );
};

export default CarteCreateEdit;
