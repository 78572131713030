import React, { useMemo } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import Icon from '@soosap/sushi/Icon';
import FieldSet from '@soosap/sushi/FieldSet';
import NumberField from '@soosap/sushi/Form/NumberField';
import TextField from '@soosap/sushi/Form/TextField';

import CloseIcon from 'icons/Close';
import { Quote } from 'server/src/app/carte/types';
import styles from './QuotesFieldArray.module.scss';

export interface Props {
  initialValue: Quote[];
  isSuggestion?: boolean;
}

const QuotesFieldArray: React.FC<Props> = ({
  initialValue: initialValueRaw,
  isSuggestion = false,
}) => {
  const initialValue = useMemo(() => initialValueRaw, []);
  const { fields } = useFieldArray('quotes', {
    multiple: true,
    initialValue,
  });

  return (
    <div className={styles[`QuotesFieldArray`]}>
      <FieldSet key={name} className={styles[`QuotesFieldArray__fieldset`]}>
        {fields.map((name, index) => (
          <div
            key={`${name}${index}`}
            className={styles[`QuotesFieldArray__field`]}
          >
            <Icon
              className={styles[`QuotesFieldArray__close`]}
              svg={CloseIcon}
              onClick={() => fields.remove(index)}
            />
            <TextField
              label={index === 0 ? 'Mengeneinheit' : undefined}
              name={`${name}.description`}
              placeholder="z.B. 1x, 2cl, XXL, 0,5l"
              required
              requiredError="Pflichtfeld"
            />
            <NumberField
              label={
                index === 0
                  ? isSuggestion
                    ? 'Preisempfehlung'
                    : 'Preis'
                  : undefined
              }
              name={`${name}.price`}
              placeholder="z.B. 950 für 9,50€"
              required
              requiredError="Pflichtfeld"
            />
          </div>
        ))}
      </FieldSet>
    </div>
  );
};

export default QuotesFieldArray;
