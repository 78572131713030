import React from 'react';

import { CarteType } from 'server/src/app/carte/types';
import Container from 'atoms/Container';
import SimpleList from 'molecules/SimpleList';
import Title from 'atoms/Title';
import styles from './CarteIndex.module.scss';

export interface Props {}

const CarteIndex: React.FC<Props> = () => {
  return (
    <Container className={styles[`CarteIndex`]}>
      <Title backTo="/admin">Carte Index</Title>
      <Container.Inner>
        <SimpleList>
          <SimpleList.Item
            to="/admin/cartes/abendkarte"
            right={
              <span className={styles[`CarteIndex__subtitle`]}>
                /speisekarte
              </span>
            }
          >
            Abendkarte
          </SimpleList.Item>
          <SimpleList.Item
            to="/admin/cartes/mittagstisch"
            right={
              <span className={styles[`CarteIndex__subtitle`]}>
                /mittagstisch
              </span>
            }
          >
            Mittagstisch
          </SimpleList.Item>
          <SimpleList.Item
            to="/admin/cartes/take-away"
            right={
              <span className={styles[`CarteIndex__subtitle`]}>/take-away</span>
            }
          >
            Take Away
          </SimpleList.Item>
          <SimpleList.Item to="/admin/cartes/weinkarte">
            Weinkarte
          </SimpleList.Item>
          <SimpleList.Item to="/admin/cartes/getrankekarte">
            Getränkekarte
          </SimpleList.Item>
          <SimpleList.Item
            borderTop
            to={`/admin/cartes/types/${CarteType.PRIVATE}`}
          >
            PRIVATE
          </SimpleList.Item>
          <SimpleList.Item to={`/admin/cartes/types/${CarteType.CATERING}`}>
            CATERING
          </SimpleList.Item>
          <SimpleList.Item to={`/admin/cartes/types/${CarteType.INTERNAL}`}>
            INTERNAL
          </SimpleList.Item>
        </SimpleList>
      </Container.Inner>
    </Container>
  );
};

export default CarteIndex;
