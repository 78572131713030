import React from 'react';
import { Redirect as ReachRouterRedirect } from '@reach/router';

export interface Props {
	path: string;
	to: string;
}

const Redirect: React.FC<Props> = ({ path, to }) => {
	return <ReachRouterRedirect noThrow path={path} to={to} />;
};

export default Redirect;
