import React from 'react';

import Item, {
  Props as GroceryListItemProps,
} from './components/GroceryListItem';
import styles from './GroceryList.module.scss';

interface FC<P = {}> extends React.FC<P> {
  Item: React.FC<GroceryListItemProps>;
}

export interface Props {
  title?: string;
}

const GroceryList: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles[`GroceryList`]}>
      {title && <div className={styles[`GroceryList__title`]}>{title}</div>}
      <ul className={styles[`GroceryList__list`]}>{children}</ul>
    </div>
  );
};

GroceryList.Item = Item;

export default GroceryList;
