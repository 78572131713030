import React from 'react';
import { RouteComponentProps, Router, RouterProps } from '@reach/router';

import Redirect, { Props as RedirectProps } from './components/Redirect';

interface Props extends RouteComponentProps {
  component: React.ComponentType | string;
}

interface FC<P = {}> extends React.FC<P> {
  Router: React.ComponentType<RouterProps & React.HTMLProps<HTMLDivElement>>;
  Redirect: React.FC<RedirectProps>;
}

const Route: FC<Props> = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};

Route.Router = Router;
Route.Redirect = Redirect;

export default Route;
