import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import Icon, { IconTheme } from '@soosap/sushi/Icon';

import { GiftCards } from 'server/src/app/giftcard/types';
import { Environment } from 'server/src/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import SimpleList from 'molecules/SimpleList';
import CheckIcon from 'icons/Check';
import styles from './GiftCardIndex.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

export interface Props {}

const GiftCardIndex: React.FC<Props> = () => {
  const [giftCards, setGiftCards] = useState<GiftCards>({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${GATSBY_BACKEND_URL}/api/giftcards`, {
        auth: {
          username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
          password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
        },
      });

      setGiftCards(data);
    };

    fetchData();
  }, []);

  const summary = Object.values(giftCards)
    .filter(giftCard => giftCard.nominalReceived === true)
    .filter(giftCard =>
      process.env.NODE_ENV === Environment.PRODUCTION
        ? giftCard.env === Environment.PRODUCTION
        : true
    )
    .reduce(
      (prev, curr) => {
        return {
          outstanding:
            prev.outstanding + (curr.nominalAmount - curr.redeemedAmount) / 100,
          redeemed: prev.redeemed + curr.redeemedAmount / 100,
        };
      },
      { outstanding: 0, redeemed: 0 }
    );

  return (
    <Container className={styles[`GiftCardIndex`]}>
      <div className={styles[`GiftCardIndex__pie`]}>
        <Doughnut
          data={{
            labels: ['Redeemed', 'Outstanding'],
            datasets: [
              {
                data: [summary.redeemed, summary.outstanding],
                backgroundColor: ['lightgrey', 'orangered'],
              },
            ],
          }}
        />
      </div>
      <Title backTo="/admin">Giftcard Index</Title>
      <Container.Inner>
        <SimpleList>
          {Object.values(giftCards)
            .filter(giftCard =>
              process.env.NODE_ENV === Environment.PRODUCTION
                ? giftCard.env === Environment.PRODUCTION
                : true
            )
            .sort((a, b) => b.orderedAt - a.orderedAt)
            .map(giftCard => (
              <SimpleList.Item
                key={giftCard.code}
                to={`/admin/giftcards/${giftCard.code}`}
                right={
                  <div className={styles[`GiftCardIndex__item-right`]}>
                    {giftCard.nominalReceived &&
                      giftCard.nominalAmount - giftCard.redeemedAmount > 0 &&
                      `${(
                        (giftCard.nominalAmount - giftCard.redeemedAmount) /
                        100
                      ).toFixed(2)} EUR`}
                    {giftCard.nominalReceived ? (
                      <Icon
                        className={styles[`GiftCardIndex__check`]}
                        svg={CheckIcon}
                        theme={IconTheme.SECONDARY}
                      />
                    ) : null}
                  </div>
                }
              >
                {giftCard.code}
              </SimpleList.Item>
            ))}
        </SimpleList>
      </Container.Inner>
    </Container>
  );
};

export default GiftCardIndex;
