import React from 'react';
import { Link } from 'gatsby';

import styles from './ListItem.module.scss';

export interface Props {
  to: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  borderTop?: boolean;
  title?: string;
}

const ListItem: React.FC<Props> = ({
  children,
  left,
  right,
  to,
  borderTop = false,
  title,
}) => {
  return (
    <li
      className={`${styles[`ListItem`]} ${
        borderTop ? styles[`ListItem--border-top`] : ''
      }`}
      title={title}
    >
      <Link className={styles[`ListItem__link`]} to={to}>
        <div className={styles[`ListItem__left`]}>{children || left}</div>
        <div className={styles[`ListItem__right`]}>{right}</div>
      </Link>
    </li>
  );
};

export default ListItem;
