import React, { useState, useEffect } from 'react';
import axios from 'axios';
import slugify from 'lodash.kebabcase';
import { useParams, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Form from '@soosap/sushi/Form';
import NumberField from '@soosap/sushi/Form/NumberField';
import arrayMutators from 'final-form-arrays';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';
import ToggleField from '@soosap/sushi/Form/ToggleField';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import WarningIcon from 'icons/Warning';
import PlusIcon from 'icons/Plus';
import {
  Offer,
  Carte,
  ProductType,
  Food,
  Beverages,
  Quote,
  Bucket,
} from 'server/src/app/carte/types';
import QuotesArrayField from '../QuotesFieldArray';
import VariantsFieldArray from '../VariantsFieldArray';
import styles from './OfferCreateEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  carteId: string;
  offerId?: string;
  bucketId?: string;
}

type FormValues = Omit<Offer, 'id' | 'createdAt'> & {
  bucketId?: string;
  productId: string;
  quotes?: Quote[];
};

export interface Props {}

const OfferCreateEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [carte, setCarte] = useState<Carte>();
  const [offer, setOffer] = useState<Offer>();
  const [products, setProducts] = useState<Food | Beverages>();
  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
  const [bucketOptions, setBucketOptions] = useState<SelectOption[]>([]);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const productType = urlParams.get('productType') as ProductType;

  const backToCarte = params.carteId.startsWith('mittagstisch')
    ? `/admin/cartes/mittagstisch?calendarWeek=${
        params.carteId.split('-kw-')[1]
      }`
    : `/admin/cartes/${params.carteId}`;

  useEffect(() => {
    const fetchData = async () => {
      if (params.carteId) {
        const [carte, food, beverages] = await Promise.all([
          axios.get<Carte>(
            `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`
          ),
          productType === ProductType.FOOD_ITEM
            ? axios.get<Food>(`${GATSBY_BACKEND_URL}/api/cartes/food`)
            : undefined,
          productType === ProductType.BEVERAGE
            ? axios.get<Beverages>(`${GATSBY_BACKEND_URL}/api/cartes/beverages`)
            : undefined,
        ]);

        const products = food || beverages;
        const productOptions: SelectOption[] = products
          ? Object.values(products.data)
              .sort((a, b) => a.position - b.position)
              .map(product => ({
                label: `${
                  product.subtitle ? `${product.subtitle.toUpperCase()} ` : ''
                }${product.title}`,
                value: product.id,
              }))
          : [];

        const bucketOptions: SelectOption[] = Object.values(
          carte.data.buckets || {}
        ).map(bucket => ({ label: bucket.title, value: bucket.id }));

        setCarte(carte.data);
        setBucketOptions(bucketOptions);
        const offer =
          params.bucketId && params.offerId
            ? carte.data.buckets[params.bucketId].offers[params.offerId]
            : undefined;

        setOffer(offer);
        setProductOptions(productOptions);
        products && setProducts(products.data);
      }
    };

    fetchData();
  }, []);

  const onDelete = async () => {
    try {
      if (!carte || !products || !params.offerId) return;

      delete carte.buckets[params.bucketId || 'default'].offers[params.offerId];
      const { data: submitErrors } = await axios.post(
        `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`,
        carte
      );

      if (submitErrors) return submitErrors;

      addToast(`Delete erfolgreich`);
      navigate(backToCarte);
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  const onSubmit = async (values: FormValues) => {
    try {
      if (!carte || !products) return;
      const offerId = values.productId;

      const defaultBucket: Bucket = {
        id: 'default',
        position: 0,
        title: 'Default',
        offers: {},
      };

      const updatedCarte: Carte = {
        ...carte,
        buckets: {
          ...(carte.buckets || {}),
          [values.bucketId || 'default']: {
            ...(values.bucketId
              ? (carte.buckets || {})[values.bucketId]
              : defaultBucket),
            offers: {
              ...((carte.buckets || {})[values.bucketId || 'default'] || {})
                .offers,
              [offerId]: {
                id: offerId,
                bucketId: values.bucketId,
                product: products[values.productId],
                productType,
                createdAt: new Date().getTime(),
                active: values.active,
                position: values.position,
                quotes: Object.values(values.quotes).reduce((prev, curr) => {
                  const quoteId = slugify(curr.description);
                  return { ...prev, [quoteId]: { ...curr, id: quoteId } };
                }, {}),
                variants: values.variants
                  ? Object.values(values.variants).reduce((prev, curr) => {
                      const variantId = slugify(curr.title);
                      return {
                        ...prev,
                        [variantId]: { ...curr, id: variantId },
                      };
                    }, {})
                  : {},
              },
            },
          },
        },
      };

      const { data: submitErrors } = await axios.post(
        `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`,
        updatedCarte
      );

      if (submitErrors) return submitErrors;

      addToast('Update erfolgreich');
      navigate(backToCarte);
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (!carte || !products) return null;

  return (
    <Container className={styles[`OfferCreateEdit`]}>
      <Title backTo={backToCarte}>
        Offer {params.offerId ? 'Edit' : 'Create'}
      </Title>
      <Container.Inner>
        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            ...offer,
            quotes: params.offerId
              ? Object.values(offer?.quotes || {})
              : undefined,
            variants: params.offerId
              ? Object.values(offer?.variants || {})
              : undefined,
            productId: offer?.product.id,
          }}
          render={({ handleSubmit, form, invalid, submitting, values }) => {
            const product = products[(values as FormValues).productId];
            const quotesInitialValue = product && Object.values(product.quotes);

            return (
              <form
                onSubmit={() =>
                  (handleSubmit(event) as Promise<object>).then(form.reset)
                }
              >
                <SelectField
                  name="productId"
                  label="Produkt"
                  placeholder="Produkt auswählen"
                  required
                  requiredError="Pflichtfeld"
                  onChange={selected => {
                    if ((values as FormValues).quotes) {
                      const product =
                        products[(selected as SelectOption).value as string];

                      form.mutators.removeBatch(
                        'quotes',
                        (values as FormValues).quotes.map(q => q.id)
                      );

                      Object.values(product.quotes).forEach(quote => {
                        form.mutators.push('quotes', quote);
                      });
                    }
                  }}
                  options={productOptions}
                  disabled={params.offerId ? true : false}
                />
                {bucketOptions.length > 0 &&
                !(
                  Object.keys(carte?.buckets || {}).length === 1 &&
                  Object.keys(carte?.buckets || {}).includes('default')
                ) ? (
                  <SelectField
                    name="bucketId"
                    label="Bucket"
                    required
                    requiredError="Pflichtfeld"
                    placeholder="Bucket auswählen"
                    options={bucketOptions}
                    disabled={params.offerId ? true : false}
                  />
                ) : null}
                <ToggleField
                  name="active"
                  required
                  requiredError="Pflichtfeld"
                  label="Active"
                  primary
                />
                {product && (
                  <QuotesArrayField initialValue={quotesInitialValue} />
                )}
                <NumberField
                  name="position"
                  required
                  requiredError="Pflichtfeld"
                  label="Position"
                  placeholder="z.B. 1"
                  initialValue={1}
                />
                <VariantsFieldArray
                  initialValue={
                    offer?.variants ? Object.values(offer.variants) : []
                  }
                />
                <Button.Group>
                  <Button
                    type="submit"
                    disabled={invalid || submitting}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {params.offerId ? 'Edit' : 'Create'}
                  </Button>
                  {values.productId && (
                    <Button
                      icon={PlusIcon}
                      onClick={() => {
                        const quotes = form.getFieldState('quotes');
                        form.mutators.push('quotes', {
                          position: 1 + quotes?.value.length || 0,
                        });
                      }}
                    >
                      Quote
                    </Button>
                  )}
                  <Button
                    icon={PlusIcon}
                    onClick={() => {
                      const variants = form.getFieldState('variants');
                      form.mutators.push('variants', {
                        position: 1 + variants?.value.length || 0,
                      });
                    }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    Variant
                  </Button>
                  {params.offerId && (
                    <Button
                      disabled={submitting}
                      borderless
                      theme={ButtonTheme.ERROR}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </form>
            );
          }}
        />
      </Container.Inner>
    </Container>
  );
};

export default OfferCreateEdit;
