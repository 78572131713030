import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';
import axios from 'axios';
import Button from '@soosap/sushi/Button';
import InputAction from '@soosap/sushi/InputAction';
import { useToasts } from '@soosap/sushi/Toast';
import Dialog from '@soosap/sushi/Dialog';
import Form from '@soosap/sushi/Form/Form';
import NumberField from '@soosap/sushi/Form/NumberField';

import { GiftCard, ShippingMethod } from 'server/src/app/giftcard/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import { FormApi } from 'final-form';
import Icon from '@soosap/sushi/Icon';
import CloseIcon from 'icons/Close';
import styles from './GiftCardEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  giftCardCode: string;
}

export interface Props {}

const GiftCardEdit: React.FC<Props> = () => {
  const params: Params = useParams();
  const { addToast } = useToasts();
  const [giftCard, setGiftCard] = useState<GiftCard>();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: giftCard } = await axios.get<GiftCard>(
        `${GATSBY_BACKEND_URL}/api/giftcards/${params.giftCardCode}`,
        {
          auth: {
            username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
            password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
          },
        }
      );

      setGiftCard(giftCard);
    };

    fetchData();
  }, []);

  const onRedeem = async (
    values: { redemptionAmount: number },
    form: FormApi
  ) => {
    try {
      const { data, status } = await axios.post(
        `${GATSBY_BACKEND_URL}/api/giftcards/redeem`,
        {
          giftCardCode: params.giftCardCode,
          redemptionAmount: values.redemptionAmount,
        },
        {
          auth: {
            username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
            password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
          },
        }
      );

      if (status === 200) {
        addToast(`${(values.redemptionAmount / 100).toFixed(2)} EUR deducted`);
        setGiftCard(data);
        setTimeout(form.reset);
        form.resetFieldState('redemptionAmount');
      }
    } catch (e) {
      return e.response.data;
    }
  };

  const onDelete = async () => {
    const { status } = await axios.delete(
      `${GATSBY_BACKEND_URL}/api/giftcards/${params.giftCardCode}`,
      {
        auth: {
          username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
          password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
        },
      }
    );

    if (status === 200) {
      addToast(`${giftCard?.code} deleted`);
      navigate('/admin/giftcards');
    }
  };

  return (
    <Container className={styles[`GiftCardEdit`]}>
      <div className={styles[`GiftCardEdit__code`]}>
        {params.giftCardCode}
        <Icon
          className={styles[`GiftCardEdit__delete`]}
          svg={CloseIcon}
          onClick={() => setDeleteConfirmOpen(true)}
        />
      </div>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={onDelete}
      >
        Do you really want to delete giftcard <strong>{giftCard?.code}</strong>?
      </Dialog>
      <Title backTo="/admin/giftcards">Giftcard Edit</Title>
      <Container.Inner>
        {giftCard ? (
          <>
            <div className={styles[`GiftCardEdit__summary`]}>
              <div>Nominal</div>
              <div
                className={`${styles[`GiftCardEdit__value`]} ${
                  styles[`GiftCardEdit__value--price`]
                }`}
              >
                {(giftCard.nominalAmount / 100).toFixed(2)} EUR
              </div>
              <div>Redeemed</div>
              <div
                className={`${styles[`GiftCardEdit__value`]} ${
                  styles[`GiftCardEdit__value--price`]
                }`}
              >
                {(giftCard.redeemedAmount / 100).toFixed(2)} EUR
              </div>
              <div>Outstanding</div>
              <div
                className={`${styles[`GiftCardEdit__value`]} ${
                  styles[`GiftCardEdit__value--price`]
                }`}
              >
                {(
                  (giftCard.nominalAmount - giftCard.redeemedAmount) /
                  100
                ).toFixed(2)}{' '}
                EUR
              </div>
            </div>
            <div className={styles[`GiftCardEdit__party`]}>
              <div className={styles[`GiftCardEdit__role`]}>OrderedAt</div>
              <div>
                {moment(giftCard.orderedAt).format('DD.MM.YYYY HH:mm [Uhr]')}
              </div>
            </div>
            <div className={styles[`GiftCardEdit__party`]}>
              <div className={styles[`GiftCardEdit__role`]}>Recipient</div>
              <div>{giftCard.recipientFullName}</div>
              {giftCard.recipientEmail && <div>{giftCard.recipientEmail}</div>}
              {giftCard.recipientAddress && (
                <div>{giftCard.recipientAddress}</div>
              )}
              {giftCard.recipientPostCode && (
                <div>
                  {giftCard.recipientPostCode} {giftCard.recipientCity}
                </div>
              )}
            </div>
            <div className={styles[`GiftCardEdit__party`]}>
              <div className={styles[`GiftCardEdit__role`]}>Giver</div>
              <div>{giftCard.giverFullName}</div>
              {giftCard.giverPhoneNumber && (
                <div>{giftCard.giverPhoneNumber}</div>
              )}
            </div>
            <div className={styles[`GiftCardEdit__cta`]}>
              {giftCard.nominalReceived ? (
                <Form
                  onSubmit={onRedeem as any}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <InputAction expand="left">
                        <NumberField
                          name="redemptionAmount"
                          placeholder="Einzulösender Betrag in Cent"
                          required
                          requiredError="Pflichtfeld"
                        />
                        <Button
                          type="submit"
                          onMouseDown={e => e.preventDefault()}
                        >
                          Redeem
                        </Button>
                      </InputAction>
                    </form>
                  )}
                />
              ) : (
                <>
                  <Button
                    className={styles[`GiftCardEdit__nominal-received-btn`]}
                    onClick={async () => {
                      const { data, status } = await axios.post(
                        `${GATSBY_BACKEND_URL}/api/giftcards/nominal-received`,
                        {
                          giftCardCode: params.giftCardCode,
                        },
                        {
                          auth: {
                            username: `${process.env.GATSBY_BASIC_AUTH_USERNAME}`,
                            password: `${process.env.GATSBY_BASIC_AUTH_PASSWORD}`,
                          },
                        }
                      );

                      if (status === 200) {
                        if (giftCard.shippingMethod === ShippingMethod.EMAIL) {
                          addToast(
                            `GiftCard activated and sent to:\n${giftCard.recipientEmail}`,
                            { autoDismiss: false }
                          );
                        } else {
                          addToast(`GiftCard activated`, {
                            autoDismiss: false,
                          });
                        }
                        setGiftCard(data);
                      }
                    }}
                  >
                    Nominal received in cash
                  </Button>
                </>
              )}
            </div>
          </>
        ) : null}
      </Container.Inner>
    </Container>
  );
};

export default GiftCardEdit;
