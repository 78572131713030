import React, { useEffect, useState } from 'react';
import slugify from 'lodash.kebabcase';
import axios from 'axios';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import Form from '@soosap/sushi/Form';
import TextField from '@soosap/sushi/Form/TextField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import NumberField from '@soosap/sushi/Form/NumberField';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';

import { Bucket, Carte } from 'server/src/app/carte/types';
import WarningIcon from 'icons/Warning';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import styles from './BucketCreateEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  carteId: string;
  bucketId?: string;
}

type FormValues = Pick<
  Bucket,
  'title' | 'titleInTamil' | 'description' | 'position'
>;

export interface Props {}

const BucketCreateEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [carte, setCarte] = useState<Carte>();

  useEffect(() => {
    const fetchData = async () => {
      const { data: carte } = await axios.get<Carte>(
        `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`
      );

      setCarte(carte);
    };

    fetchData();
  }, []);

  const onDelete = async () => {
    if (!carte) throw new Error('Carte is required');
    if (!params.bucketId) throw new Error('bucketId is required');

    // DELETE
    delete carte.buckets[params.bucketId];

    const res = await axios.post(
      `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`,
      carte
    );
    addToast(res.data);
    navigate(`/admin/cartes/${params.carteId}`);
  };

  const onSubmit = async (values: FormValues) => {
    try {
      if (!carte) throw new Error('Carte is required');
      let updatedCarte: Carte;

      if (params.bucketId) {
        // UPDATE
        updatedCarte = {
          ...carte,
          buckets: {
            ...carte.buckets,
            [params.bucketId]: { ...carte.buckets[params.bucketId], ...values },
          },
        };
      } else {
        // CREATE
        const bucketId = slugify(values.title);
        if (Object.keys(carte.buckets || {}).includes(bucketId)) {
          return { title: `Bucket "${values.title}" existiert bereits!` };
        }

        const newBucket: Bucket = { ...values, id: bucketId, offers: {} };

        updatedCarte = {
          ...carte,
          buckets: {
            ...(carte.buckets || {}),
            [bucketId]: newBucket,
          },
        };
      }

      const res = await axios.post(
        `${GATSBY_BACKEND_URL}/api/cartes/${params.carteId}`,
        updatedCarte
      );

      addToast(res.data);
      navigate(`/admin/cartes/${params.carteId}`);
    } catch (e) {
      addToast(e.message, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  return (
    <Container className={styles[`BucketCreateEdit`]}>
      <Title backTo={`/admin/cartes/${params.carteId}`}>
        Bucket {params.bucketId ? 'Edit' : 'Create'}
      </Title>
      <Container.Inner>
        <Form
          onSubmit={onSubmit}
          initialValues={
            params.bucketId ? carte?.buckets[params.bucketId] : undefined
          }
          render={({ handleSubmit, form, invalid, submitting }) => {
            return (
              <form
                onSubmit={() =>
                  (handleSubmit(event) as Promise<object>).then(form.reset)
                }
              >
                <TextField
                  name="title"
                  required
                  requiredError="Pflichtfeld"
                  label="Titel"
                  placeholder="z.B. Vorspeise, Hauptgänge, Desserts, etc."
                  disabled={params.bucketId ? true : false}
                />
                <TextField
                  name="titleInTamil"
                  label="Titel in Tamil"
                  placeholder="optional | z.B. ஸ்டார்டர், இனிப்பு, etc."
                />
                <TextAreaField
                  name="description"
                  label="Beschreibung"
                  placeholder={`optional | z.B. "Verführerischer Abschluss: Paradiesisch gute Desserts laden zum Schwelgen und Geniessen ein"`}
                />
                <NumberField
                  name="position"
                  required
                  requiredError="Pflichtfeld"
                  label="Position"
                  placeholder="z.B. 1"
                  initialValue={1}
                />
                <Button.Group>
                  <Button
                    type="submit"
                    disabled={invalid || submitting}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {params.bucketId ? 'Edit' : 'Create'}
                  </Button>
                  {params.bucketId && (
                    <Button
                      disabled={submitting}
                      borderless
                      theme={ButtonTheme.ERROR}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </form>
            );
          }}
        />
      </Container.Inner>
    </Container>
  );
};

export default BucketCreateEdit;
