import React from 'react';

import styles from './GroceryListItem.module.scss';

export interface Props {}

const GroceryListItem: React.FC<Props> = ({ children }) => {
  return <div className={styles[`GroceryListItem`]}>{children}</div>;
};

export default GroceryListItem;
