import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import Form from '@soosap/sushi/Form';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import SlugField from '@soosap/sushi/Form/SlugField';
import NumberField from '@soosap/sushi/Form/NumberField';

import Container from 'atoms/Container';
import Title from 'atoms/Title';
import WarningIcon from 'icons/Warning';
import { Additive } from 'server/src/app/carte/types';
import styles from './AdditiveCreateEdit.module.scss';

const { GATSBY_BACKEND_URL } = process.env;

interface Params {
  additiveId?: string;
}

type FormValues = Pick<Additive, 'id' | 'label' | 'position'>;

export interface Props {}

const AdditiveCreateEdit: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const params: Params = useParams();
  const [initialValues, setInitialValues] = useState<FormValues>();

  useEffect(() => {
    const fetchData = async () => {
      if (params.additiveId) {
        const { data } = await axios.get(
          `${GATSBY_BACKEND_URL}/api/cartes/additives/${params.additiveId}`
        );

        setInitialValues(data);
      }
    };

    fetchData();
  }, []);

  const onDelete = async () => {
    const res = await axios.delete(
      `${GATSBY_BACKEND_URL}/api/cartes/additives/${params.additiveId}`
    );
    addToast(res.data);
    navigate('/admin/additives');
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const res = params.additiveId
        ? await axios.patch(
            `${GATSBY_BACKEND_URL}/api/cartes/additives/${params.additiveId}`,
            values
          )
        : await axios.post(
            `${GATSBY_BACKEND_URL}/api/cartes/additives`,
            values
          );
      addToast(res.data);
      navigate('/admin/additives');
    } catch (e) {
      addToast(`Fehler! Bitte anrufen ☎ 07131 405 11 70`, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  if (params.additiveId && !initialValues) return null;

  return (
    <Container className={styles[`AdditiveCreateEdit`]}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, invalid, submitting }) => (
          <form onSubmit={event => handleSubmit(event)?.then(form.reset)}>
            <Title backTo="/admin/additives">
              Additive {params.additiveId ? 'Edit' : 'Create'}
            </Title>
            <Container.Inner>
              <FieldSet className={styles[`AdditiveCreateEdit__fieldset`]}>
                <SlugField
                  name="id"
                  required
                  requiredError="Pflichtfeld"
                  label="Slug"
                  placeholder="z.B. konservierungsstoff"
                  disabled={params.additiveId ? true : false}
                />
                <TextField
                  name="label"
                  required
                  requiredError="Pflichtfeld"
                  label="Bezeichnung"
                  placeholder="z.B. mit Konservierungsstoff"
                />
                <NumberField
                  name="position"
                  required
                  requiredError="Pflichtfeld"
                  label="Position"
                  placeholder="z.B. 1"
                />
                <Button.Group>
                  <Button
                    type="submit"
                    disabled={invalid || submitting}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {params.additiveId ? 'Edit' : 'Create'}
                  </Button>
                  {params.additiveId && (
                    <Button
                      disabled={submitting}
                      borderless
                      theme={ButtonTheme.ERROR}
                      onClick={onDelete}
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </FieldSet>
            </Container.Inner>
          </form>
        )}
      />
    </Container>
  );
};

export default AdditiveCreateEdit;
