import React from 'react';
import { useField } from 'react-final-form';
import NumberField from '@soosap/sushi/Form/NumberField';
import styles from '../BeverageCreateEdit.module.scss';

export interface Props {}

const AlcoholConcentration: React.FC<Props> = () => {
  const spirituousField = useField('spirituous');

  return (
    spirituousField.input.value && (
      <NumberField
        name="alcoholConcentration"
        label="Alkoholgehalt"
        className={styles[`BeverageCreateEdit__concentration`]}
        placeholder="z.B. 12.0 % vol"
        required
      />
    )
  );
};

export default AlcoholConcentration;
